.map-page {
    display: flex;
    flex-direction: column;
    touch-action: pan-x pan-y;
    overflow: hidden;
}

.toggle-button {
    position: absolute;
    margin-top: 10px;
    right: 20px;
    max-width: 50px;
    padding: 5px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    z-index: 2;
}

.toggle-button.light {
    background-color: #333;
    color: #f0f0f0;
}

.toggle-button.dark {
    background-color: #f0f0f0;
    color: #333;
}

.toggle-button:focus {
    outline: white;
}

.toggle-button:hover {
    background-color: lightgrey;
    color: #323232;
}

.refresh-button {
    position: absolute;
    margin-top: 10px;
    right: 20px;
    padding: 10px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.marker {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgba(0, 255, 255, 0);
    cursor: pointer;
    transition: transform 0.3s;
    z-index: 100;
}

.marketText {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: rgba(0, 255, 255, 0);
    cursor: pointer;
    transition: transform 0.3s;
    z-index: 100;
}

.zoom-button-container {
    position: absolute;
    bottom: 130px;
    left: 20px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50px;
    height: 150px;
}

.zoom-buttons {
    width: 70px;
    height: 200px;
    border-radius: 20px;
    background: rgba(121, 121, 121, 0.3);
    background-color: rgba(177, 177, 177, 0.3);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    color: var(--color);
    border: none;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
    z-index: 999;
}

.zoom-buttons:hover {
    background-color: rgba(121, 121, 121, 0.5);
}

.zoom-buttons:active {
    background-color: rgba(121, 121, 121, 0.7);
}