.violation-card-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: left;
    z-index: 5;
}

.violation-card {
    width: 30%;
    height: 300px;
    max-height: 75vh;
    background-color: #fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.2);
}

.violation-card.open {
    transform: translateY(0);
    height: 25vh;
    min-height: 300px;
    margin-left: 250px;
}

.violation-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: rgb(255, 248, 185);
}

.content {
    color: #323232;
    padding: 10px;
}

.close-button {
    position: absolute;
    top: 30px;
    right: 30px;
    background-color: #bebebe;
    color: #323232;
    border: none;
    font-size: 18px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: background-color 0.2s ease;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
}

.close-button:hover {
    background-color: black;
}

.icon {
    width: 220px;
    margin-right: 10px;
    color: black;
    margin-left: 20px;
}

.header-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px;
    margin-left: 0px;
    font-weight: 300;
}

.icon img {
    width: 150px;
    height: auto;
    vertical-align: middle;
    display: inline-block;
    margin-top: 5px;
    margin-left: -20px;
}

.title-card {
    display: flex;
    align-items: center;
    width: 1000px;
    color: #323232;
    padding: 10px;
    border: 0px solid transparent;
    border-radius: 20px;
    box-shadow: 2px 2px 8px 3px rgba(0, 0, 0, 0.2);
    background-color: rgb(255, 252, 220);
    margin: 5px;
}

.title-card-header {
    font-size: 25px;
    font-weight: 700;
    margin-left: -70px;
    margin-right: -70px;
}

.title-info-description {
    line-height: 1.5;
}

.time-parked-container {
    display: flex;
    flex-direction: column;
}

.time-parked-container>div {
    display: flex;
    margin-right: 10px;
}

.time-parked {
    padding-left: 4px;
}

.button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 10px;
    border-color: transparent;
}

.citation-button {
    color: #323232;
    background-color: rgb(255, 248, 185);
    font-size: 18px;
    cursor: pointer;
    z-index: 100;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.5)
}

.citation-button.darkened {
    opacity: 0.7;
    pointer-events: none;
    cursor: not-allowed;
}

.citation-button.checked {
    opacity: 0.7;
    pointer-events: none;
    cursor: not-allowed;
    background-color: #aad7a4;
}