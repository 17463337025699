body {
    margin: 0;
    background-color: #202225;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

@layer utilities {
    .font-stretched {
        font-stretch: semi-expanded;
    }

    .up-lg {
        box-shadow: 0 -15px 30px -10px rgba(0, 0, 0, 0.5)
    }

    .up-sm {
        box-shadow: 0 -5px 20px -10px rgba(0, 0, 0, 0.3)
    }

    .down-lg {
        box-shadow: 0 15px 30px -15px rgba(0, 0, 0, 0.5)
    }

    .fr {
        display: flex;
        flex-direction: row;
    }

    .fc {
        display: flex;
        flex-direction: column;
    }

    .flex-col-center {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .fcc {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .flex-row-center {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }

    .frc {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }

    .blend {
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
    }

    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .text-glow {
        text-shadow: '0 0 5px #FFFFFF, 0 0 10px #FFFFFF, 0 0 20px #FFFFFF, 0 0 30px #FFE374';
    }

    .gray-gradient {
        background: linear-gradient(to right, #202225, #36393F);
    }

    .light-gray-gradient {
        background: linear-gradient(to right, #F2F3F5, #EBEDEF);
    }

    .yellow-gradient {
        background: linear-gradient(to right, #FFE374, #FFF59D);
    }


    .rubik-light {
        @apply font-rubik font-light;
    }

    .rubik-normal {
        @apply font-rubik font-normal;
    }

    .rubik-medium {
        @apply font-rubik font-medium;
    }

    .rubik-bold {
        @apply font-rubik font-bold;
    }

    .rubik-extrabold {
        @apply font-rubik font-extrabold;
    }
}

/* Hide spinner buttons */
.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.no-spinner {
    -moz-appearance: textfield;
}

:root {
    --yellow: #ffe374;
    --medium-yellow: #FAF0BC;
    --light-yellow: #FFF9E1;
    --grey: #323232;
    --medium-grey: #7F7F7F;
    --light-grey: #D9D9D9;
    --black: #000000;
    --white: #ffffff;
    --background: linear-gradient(to bottom right, #1a1a1a, #555555);
    --text-color: white;
    --text-color-dark: #0e0c0c;
    --header-color: rgba(50, 50, 50, 0.3);
    --gradient-color: rgba(50, 50, 50, 0.92);
    --lighter-grey: #F1F1F1
}

.App {
    font-family: 'SF-Pro-Text-Regular', sans-serif;
    src: local('SF-Pro-Text-Regular'), url("./assets/fonts/SF-Pro-Text-Regular.otf") format('otf');
    text-align: center;
    background-color: var(--background);
    color: var(--text-color);
    width: '100%';
    height: 100vh;
    margin-top: -25px;
    overflow: hidden;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: var(--background);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}